import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadOnboardingState, saveOnboardingState, startOverOnboarding } from './onboardingState';
import PhoneInput from './PhoneInput';
import PaymentForm from './PaymentForm';

const Step2DomainCheck = () => {
  const navigate = useNavigate();
  const initialState = loadOnboardingState();
  
  const [domainAvailable, setDomainAvailable] = useState(initialState.domainAvailable || false);
  const [purchased, setPurchased] = useState(initialState.purchased || false);
  const [useExistingDomain, setUseExistingDomain] = useState(false);
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(null);
  const [pricingError, setPricingError] = useState('');

  const [contactInfo, setContactInfo] = useState(initialState.contactInfo);

  useEffect(() => {
    const updatedState = {
      ...initialState,
      domainAvailable,
      purchased,
      contactInfo
    };
    saveOnboardingState(updatedState);
  }, [domainAvailable, purchased, contactInfo, initialState]);

  async function checkDomainAvailability() {
    setLoading(true);
    setPricingError('');
    const res = await fetch('/api/check-domain', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ domain: initialState.domain })
    });
    const data = await res.json();
    if (data.available) {
      setDomainAvailable(true);
      await fetchPricing();
    } else {
      alert('This domain is not available. Please try another.');
      setDomainAvailable(false);
      setLoading(false);
    }
  }

  async function fetchPricing() {
    setLoading(true);
    setPricingError('');
    const res = await fetch('/api/get-pricing', {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({ domain: initialState.domain })
    });
    const data = await res.json();
    setLoading(false);
    if (data.price) {
      setPrice(data.price);
    } else {
      // Pricing failed, show error
      setPricingError(data.error || 'Failed to get pricing.');
    }
  }

  function useMyExistingDomainOption() {
    setPurchased(true);
    setUseExistingDomain(true);
  }

  function handleNext() {
    const updatedState = { 
      ...initialState, 
      domainAvailable, 
      purchased, 
      contactInfo, 
      step: 3 
    };
    saveOnboardingState(updatedState);
    navigate('/onboarding/step3');
  }

  function handleBack() {
    const updatedState = { ...initialState, step: 1 };
    saveOnboardingState(updatedState);
    navigate('/onboarding/step1');
  }

  function handleStartOver() {
    startOverOnboarding();
    navigate('/onboarding/step1');
  }

  return (
    <div className="max-w-md mx-auto bg-gray-800 p-6 rounded border border-gray-700 shadow space-y-4 relative">
      <div className="absolute top-4 right-4">
        <button
          onClick={handleStartOver}
          className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-500 transition"
        >
          Start Over
        </button>
      </div>

      <h2 className="font-bold text-2xl text-[#39FF14]">Check Domain Availability</h2>
      <p className="text-gray-300">Domain: {initialState.domain}</p>

      {!domainAvailable && !price && !useExistingDomain && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            checkDomainAvailability();
          }}
          className="space-y-4"
        >
          <button
            type="submit"
            className="p-2 bg-yellow-600 text-white font-bold rounded hover:bg-yellow-500 transition"
            disabled={loading}
          >
            {loading ? 'Checking...' : 'Check Availability'}
          </button>

          {!domainAvailable && (
            <button
              type="button"
              className="mt-4 p-2 bg-gray-600 text-white rounded hover:bg-gray-500 transition"
              onClick={handleBack}
            >
              Back
            </button>
          )}
        </form>
      )}

      {loading && domainAvailable && !purchased && !useExistingDomain && !price && (
        <p className="text-gray-200">Loading pricing...</p>
      )}

      {domainAvailable && !purchased && !useExistingDomain && price && !pricingError && (
        <div className="mt-4 space-y-4">
          <h3 className="font-bold text-gray-200">Domain is available!</h3>
          <p className="text-gray-300">How would you like to proceed?</p>
          <div className="mt-2 space-y-2 text-gray-200">
            <label className="block">
              <input
                type="radio"
                name="domainOption"
                value="buy"
                defaultChecked
                onChange={() => setUseExistingDomain(false)}
              />
              <span className="ml-2">I want to buy this domain now</span>
            </label>
            <label className="block">
              <input
                type="radio"
                name="domainOption"
                value="own"
                onChange={() => setUseExistingDomain(true)}
              />
              <span className="ml-2">I already own this domain elsewhere</span>
            </label>
          </div>

          {!useExistingDomain && price && (
            <form
              onSubmit={(e) => e.preventDefault()}
              className="space-y-2"
            >
              <p className="mt-4 text-gray-200">Domain Price: ${price}</p>
              <p className="text-gray-300">Enter your contact details:</p>

              <input
                type="text"
                placeholder="First Name"
                className="border border-gray-600 p-1 w-full bg-gray-700 text-white rounded"
                value={contactInfo.firstname}
                onChange={e => setContactInfo({ ...contactInfo, firstname: e.target.value })}
              />
              <input
                type="text"
                placeholder="Last Name"
                className="border border-gray-600 p-1 w-full bg-gray-700 text-white rounded"
                value={contactInfo.lastname}
                onChange={e => setContactInfo({ ...contactInfo, lastname: e.target.value })}
              />
              <input
                type="text"
                placeholder="Address"
                className="border border-gray-600 p-1 w-full bg-gray-700 text-white rounded"
                value={contactInfo.address1}
                onChange={e => setContactInfo({ ...contactInfo, address1: e.target.value })}
              />
              <input
                type="text"
                placeholder="City"
                className="border border-gray-600 p-1 w-full bg-gray-700 text-white rounded"
                value={contactInfo.city}
                onChange={e => setContactInfo({ ...contactInfo, city: e.target.value })}
              />
              <input
                type="text"
                placeholder="State"
                className="border border-gray-600 p-1 w-full bg-gray-700 text-white rounded"
                value={contactInfo.stateprovince}
                onChange={e => setContactInfo({ ...contactInfo, stateprovince: e.target.value })}
              />
              <input
                type="text"
                placeholder="Postal Code"
                className="border border-gray-600 p-1 w-full bg-gray-700 text-white rounded"
                value={contactInfo.postalcode}
                onChange={e => setContactInfo({ ...contactInfo, postalcode: e.target.value })}
              />
              <input
                type="text"
                placeholder="Country (e.g. US)"
                className="border border-gray-600 p-1 w-full bg-gray-700 text-white rounded"
                value={contactInfo.country}
                onChange={e => setContactInfo({ ...contactInfo, country: e.target.value })}
              />
              <PhoneInput
                phone={contactInfo.phone}
                setPhone={val => setContactInfo({ ...contactInfo, phone: val })}
              />
              <input
                type="email"
                placeholder="Email"
                className="border border-gray-600 p-1 w-full bg-gray-700 text-white rounded"
                value={contactInfo.emailaddress}
                onChange={e => setContactInfo({ ...contactInfo, emailaddress: e.target.value })}
              />

              <PaymentForm
                domain={initialState.domain}
                price={price}
                onPaymentSuccess={() => {
                  // Payment success triggers domain purchase automatically on callback
                }}
              />
            </form>
          )}

          {useExistingDomain && (
            <div className="mt-4">
              <p className="text-gray-200">No purchase needed. We�ll just use {initialState.domain} as is.</p>
              <button
                className="mt-2 p-2 bg-green-600 text-white font-bold rounded hover:bg-green-500 transition"
                onClick={useMyExistingDomainOption}
              >
                Use My Existing Domain
              </button>
            </div>
          )}
        </div>
      )}

      {pricingError && (
        <div className="text-red-400 mt-4">
          <p>Failed to fetch pricing: {pricingError}</p>
          <p>You can try another domain or go back.</p>
          <form onSubmit={(e)=>{e.preventDefault();handleBack();}}>
            <button
              type="submit"
              className="mt-2 p-2 bg-gray-600 text-white rounded hover:bg-gray-500 transition"
            >
              Back
            </button>
          </form>
        </div>
      )}

      {useExistingDomain && purchased && !pricingError && (
        <div className="mt-4">
          <h3 className="font-bold text-green-400">Using Your Existing Domain!</h3>
          <p className="text-gray-200">Great! Click next to continue.</p>
          <form onSubmit={(e)=>{e.preventDefault();handleNext();}}>
            <button
              type="submit"
              className="mt-2 p-2 bg-[#39FF14] text-gray-900 font-bold rounded hover:opacity-90 transition"
            >
              Next
            </button>
          </form>
        </div>
      )}

      {!domainAvailable && !price && !loading && !useExistingDomain && (
        <form onSubmit={(e)=>{e.preventDefault();handleBack();}}>
          <button
            type="submit"
            className="mt-4 p-2 bg-gray-600 text-white rounded hover:bg-gray-500 transition"
          >
            Back
          </button>
        </form>
      )}

      {purchased && domainAvailable && !useExistingDomain && price && !pricingError && (
        <form onSubmit={(e)=>{e.preventDefault();handleNext();}}>
          <button
            type="submit"
            className="mt-4 p-2 bg-[#39FF14] text-gray-900 font-bold rounded hover:opacity-90 transition"
          >
            Next
          </button>
        </form>
      )}
    </div>
  );
};

export default Step2DomainCheck;
