import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadOnboardingState, startOverOnboarding } from './onboardingState';

const Step5Complete = () => {
  const navigate = useNavigate();
  const state = loadOnboardingState();

  useEffect(() => {
    // Save final deployment if needed (the original code did)
    (async () => {
      await fetch('/api/set-deployment', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ domain: state.domain, dropletName: state.dropletName })
      });
    })();
    // eslint-disable-next-line
  }, []);

  function handleStartOver() {
    startOverOnboarding();
    navigate('/onboarding/step1');
  }

  return (
    <div className="max-w-md mx-auto bg-gray-800 p-6 rounded border border-gray-700 shadow space-y-4 relative">
      <div className="absolute top-4 right-4">
        <button
          onClick={handleStartOver}
          className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-500 transition"
        >
          Start Over
        </button>
      </div>
      <h2 className="font-bold text-2xl text-[#39FF14]">Setup Complete!</h2>
      <p className="text-gray-200">Your SaaS is now live on <strong>{state.domain}</strong>.</p>
      <p className="text-gray-300">Droplet: {state.dropletName}</p>
      <p className="text-gray-400">
        You can now access your SaaS frontend and manage it via your dashboard.
      </p>
      <a
        href="/dashboard"
        className="inline-block bg-[#39FF14] text-gray-900 p-2 rounded font-bold hover:opacity-90 transition"
      >
        Go to Dashboard
      </a>
    </div>
  );
};

export default Step5Complete;
