import React, { useState } from 'react';

/**
 * BrandingForm
 *
 * Allows users to upload a logo and define brand colors.
 * Integrates a neon-green accent and a dark theme for a striking, high-end look.
 */
const BrandingForm = () => {
  const [logoFile, setLogoFile] = useState(null);
  const [primaryColor, setPrimaryColor] = useState('#000000');
  const [secondaryColor, setSecondaryColor] = useState('#ffffff');
  const [livePreviewUrl] = useState('https://www.yourdomain.com');

  async function uploadLogo() {
    if (!logoFile) return;
    const formData = new FormData();
    formData.append('logo', logoFile);

    try {
      const res = await fetch('/api/upload-logo', {
        method: 'POST',
        body: formData
      });
      const data = await res.json();
      if (data.status === 'uploaded') {
        alert('Logo uploaded successfully!');
      } else {
        alert(`Failed to upload logo: ${data.error || 'Unknown error'}`);
      }
    } catch (error) {
      alert(`Error uploading logo: ${error.message}`);
    }
  }

  async function applyBranding() {
    try {
      const res = await fetch('/api/apply-branding', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ primaryColor, secondaryColor })
      });
      const data = await res.json();
      if (data.status === 'applied') {
        alert('Branding applied successfully!');
      } else {
        alert(`Failed to apply branding: ${data.error || 'Unknown error'}`);
      }
    } catch (error) {
      alert(`Error applying branding: ${error.message}`);
    }
  }

  return (
    <div className="min-h-screen p-6 bg-gray-900 text-white">
      <h2 className="text-3xl font-bold mb-4">Branding</h2>
      <p className="text-gray-300 mb-8">
        Upload your logo and set brand colors. Changes will reflect on your live site.
      </p>

      <div className="space-y-8 max-w-xl">
        {/* Logo Upload */}
        <div className="bg-gray-800 p-6 rounded shadow space-y-4 border border-gray-700">
          <h3 className="text-xl font-semibold">Logo Upload</h3>
          <input
            type="file"
            className="w-full text-sm text-gray-200 bg-gray-700 rounded p-2"
            onChange={e => setLogoFile(e.target.files[0])}
          />
          <button
            className="px-4 py-2 bg-[#39FF14] text-gray-900 font-bold rounded hover:opacity-90 transition"
            onClick={uploadLogo}
          >
            Upload Logo
          </button>
        </div>

        {/* Brand Colors */}
        <div className="bg-gray-800 p-6 rounded shadow space-y-4 border border-gray-700">
          <h3 className="text-xl font-semibold">Brand Colors</h3>
          <label className="block font-semibold text-gray-300">
            Primary Color:
            <input
              type="color"
              value={primaryColor}
              onChange={e => setPrimaryColor(e.target.value)}
              className="ml-2 w-10 h-10 border border-gray-600 rounded-full"
            />
          </label>
          <label className="block font-semibold text-gray-300">
            Secondary Color:
            <input
              type="color"
              value={secondaryColor}
              onChange={e => setSecondaryColor(e.target.value)}
              className="ml-2 w-10 h-10 border border-gray-600 rounded-full"
            />
          </label>
          <button
            className="px-4 py-2 bg-[#39FF14] text-gray-900 font-bold rounded hover:opacity-90 transition"
            onClick={applyBranding}
          >
            Apply Branding
          </button>
        </div>

        {/* Live Preview */}
        <div className="bg-gray-800 p-6 rounded shadow space-y-4 border border-gray-700">
          <h3 className="text-xl font-semibold">Live Preview</h3>
          <p className="text-gray-400">See your brand changes in action:</p>
          <div className="border border-gray-700">
            <iframe
              src={livePreviewUrl}
              className="w-full h-64"
              title="Brand Preview"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandingForm;
