import React from 'react';

const Login = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white p-4">
      <div className="bg-gray-800 p-6 rounded border border-gray-700 shadow space-y-4 max-w-sm w-full text-center">
        <h1 className="text-2xl font-bold text-[#39FF14]">Login</h1>
        <p className="text-gray-300">Sign in to access your dashboard.</p>
        <a
          href="/api/auth/login"
          className="mt-4 inline-block bg-[#39FF14] text-gray-900 font-bold p-2 rounded hover:opacity-90 transition"
        >
          Sign in with Google
        </a>
      </div>
    </div>
  );
};

export default Login;
