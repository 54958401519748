import React, { useState, useEffect, useMemo } from 'react';
import ReactFlow, { MiniMap, Controls, Background } from 'reactflow';
import 'reactflow/dist/style.css';

function generateGraph(structure) {
  let yOffset = 50;
  const yStep = 80;

  const pageNodes = structure.pages.map((p, i) => ({
    id: `page-${p}`,
    type: 'input',
    data: { label: p },
    position: { x: 100, y: yOffset + i * yStep }
  }));

  const frontNodes = structure.frontendComponents.map((fc, i) => ({
    id: `frontend-${fc}`,
    data: { label: fc },
    position: { x: 400, y: yOffset + i * yStep }
  }));

  const backNodes = structure.backendFiles.map((bf, i) => ({
    id: `backend-${bf}`,
    data: { label: bf },
    position: { x: 700, y: yOffset + i * yStep }
  }));

  const edges = [];
  pageNodes.forEach((pn) => {
    frontNodes.forEach((fn) => {
      edges.push({ id: `edge-${pn.id}-${fn.id}`, source: pn.id, target: fn.id });
    });
    backNodes.forEach((bn) => {
      edges.push({ id: `edge-${pn.id}-${bn.id}`, source: pn.id, target: bn.id });
    });
  });

  return { nodes: [...pageNodes, ...frontNodes, ...backNodes], edges };
}

const SiteStructure = () => {
  const [structure, setStructure] = useState(null);

  useEffect(() => {
    fetchSiteStructure();
  }, []);

  async function fetchSiteStructure() {
    const res = await fetch('/api/site-structure');
    if (res.ok) {
      const data = await res.json();
      setStructure(data);
    }
  }

  const { nodes, edges } = useMemo(() => {
    if (!structure) return { nodes: [], edges: [] };
    return generateGraph(structure);
  }, [structure]);

  if (!structure) {
    return (
      <div className="p-4 bg-gray-900 text-white min-h-screen">
        <h2 className="text-2xl font-bold mb-2 text-[#39FF14]">Site Structure</h2>
        <p className="text-gray-400">Loading site structure...</p>
      </div>
    );
  }

  return (
    <div className="space-y-4 p-4 bg-gray-900 text-white min-h-screen">
      <h2 className="text-3xl font-bold text-[#39FF14]">Site Structure</h2>
      <p className="text-gray-300">
        Visualize your SaaS's structure. Pan, zoom, and rearrange nodes for clarity.
      </p>

      <div className="w-full h-[600px] bg-gray-800 border border-gray-700 rounded overflow-hidden">
        <ReactFlow nodes={nodes} edges={edges} fitView>
          <MiniMap style={{ background: '#111' }} />
          <Controls />
          <Background />
        </ReactFlow>
      </div>

      <div className="bg-gray-800 border border-gray-700 rounded p-4">
        <h3 className="font-bold mb-2 text-gray-200">Details</h3>
        <div className="grid grid-cols-3 gap-4 text-sm text-gray-300">
          <div>
            <h4 className="font-bold text-gray-200">Pages</h4>
            <ul className="list-disc list-inside">
              {structure.pages.map(p => <li key={p}>{p}</li>)}
            </ul>
          </div>
          <div>
            <h4 className="font-bold text-gray-200">Frontend Components</h4>
            <ul className="list-disc list-inside">
              {structure.frontendComponents.map(fc => <li key={fc}>{fc}</li>)}
            </ul>
          </div>
          <div>
            <h4 className="font-bold text-gray-200">Backend Files</h4>
            <ul className="list-disc list-inside">
              {structure.backendFiles.map(bf => <li key={bf}>{bf}</li>)}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteStructure;
