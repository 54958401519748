import React, { useState, useRef } from 'react';
import { Loader2 } from 'lucide-react';

export default function VCMatcher() {
  const [messages, setMessages] = useState([
    { role: 'assistant', content: "To find the right VCs for you, what industry are you in, what stage is your startup at, and how much are you looking to raise?" }
  ]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const [processing, setProcessing] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [matches, setMatches] = useState([]);
  const [downloadLink, setDownloadLink] = useState(null);
  const [matchesFound, setMatchesFound] = useState(0);

  const eventSourceRef = useRef(null);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!inputValue.trim() || loading) return;

    const userMsg = { role: 'user', content: inputValue.trim() };
    setMessages(prev => [...prev, userMsg]);
    setLoading(true);
    setInputValue("");

    try {
      const extractRes = await fetch('/api/v1/extract-startup-info', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({ user_input: userMsg.content }),
        credentials: 'include' // IMPORTANT: include session cookie
      });

      if (!extractRes.ok) {
        setMessages(prev => [...prev, { role: 'assistant', content: "Error extracting startup info. Please try again."}]);
        setLoading(false);
        return;
      }

      const extractData = await extractRes.json();
      if (extractData.startup_info) {
        const { industries, stage, raise_amount } = extractData.startup_info;
        const industryParams = industries.map(i => `industry=${encodeURIComponent(i)}`).join('&');
        const stageParam = `stage=${encodeURIComponent(stage)}`;
        const amountParam = `raise_amount=${encodeURIComponent(raise_amount)}`;
        const sseUrl = `/api/v1/vc-hunter-stream?${industryParams}&${stageParam}&${amountParam}`;

        setProcessing(true);
        setStatusMessage("Searching VCs...");
        setMatches([]);
        setDownloadLink(null);
        setMatchesFound(0);

        // Initialize SSE with credentials
        const es = new EventSource(sseUrl, { withCredentials: true });
        eventSourceRef.current = es;

        es.onmessage = (event) => {
          const data = event.data;
          if (data.startsWith("Got it!") || data.startsWith("Loading") || data.startsWith("Analyzing") || data.startsWith("Processed")) {
            setStatusMessage(data);
          } else if (data.startsWith("Found match:")) {
            setMatchesFound(prev => prev + 1);
            const matchText = data.replace("Found match: ", "");
            const matchParts = matchText.match(/^(.*?) \((.*)\)$/);
            let firm = matchText;
            let reasons = "";
            if (matchParts && matchParts.length === 3) {
              firm = matchParts[1];
              reasons = matchParts[2];
            }
            setMatches(prev => [...prev, { firm, reasons }]);
          } else if (data.startsWith("Success!")) {
            setStatusMessage(data);
          } else if (
            data.startsWith("No strong matches") ||
            data.startsWith("Server error") ||
            data.startsWith("Insights") ||
            data.startsWith("Team Members") ||
            data.startsWith("Thesis") ||
            data.startsWith("Recent News") ||
            data.startsWith("Scraping") ||
            data.startsWith("Examining")
          ) {
            // General messages
            setStatusMessage(data);
          }
        };

        es.addEventListener('done', (event) => {
          const link = event.data;
          if (link) {
            setDownloadLink(link);
            setStatusMessage(prev => prev + " You can now download your matches.");
          }
          setProcessing(false);
          setLoading(false);
          es.close();
        });

        es.onerror = (error) => {
          console.error("SSE error:", error);
          setStatusMessage("Error occurred. Please try again later.");
          setProcessing(false);
          setLoading(false);
          es.close();
        };

      } else {
        setMessages(prev => [...prev,
          { role: 'assistant', content: "Could you clarify your industry, stage, and raise amount?"}
        ]);
        setLoading(false);
      }

    } catch (error) {
      console.error("Error during extraction:", error);
      setMessages(prev => [...prev,
        { role: 'assistant', content: "An unexpected error occurred. Check console."}
      ]);
      setLoading(false);
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-4 space-y-4">
      <h1 className="text-2xl font-bold mb-4">VC Hunter</h1>
      <p className="text-sm text-gray-600">Provide your startup details below. We'll find matching VCs and show incremental progress!</p>

      <div className="border rounded p-4 space-y-4 bg-white">
        {messages.map((m, i) => (
          <div key={i} className={m.role === 'assistant' ? 'text-left' : 'text-right'}>
            <div className={`inline-block p-2 rounded-md ${m.role === 'assistant' ? 'bg-gray-200 text-black' : 'bg-blue-200 text-black'}`}>
              {m.content}
            </div>
          </div>
        ))}
      </div>

      <form onSubmit={sendMessage} className="flex space-x-2">
        <input
          type="text"
          className="border rounded p-2 flex-1"
          placeholder="e.g. AI startup, seed stage, raising $1M"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          disabled={loading}
        />
        <button type="submit" disabled={loading} className="bg-blue-600 text-white rounded px-4 py-2">
          {loading ? '...' : 'Send'}
        </button>
      </form>

      {(processing || matchesFound > 0 || statusMessage || downloadLink) && (
        <div className="border rounded p-4 bg-white space-y-4">
          <div className="flex items-center space-x-2">
            {processing && <Loader2 className="h-5 w-5 animate-spin" />}
            <span className="font-medium">{statusMessage}</span>
            {matchesFound > 0 && (
              <span className="inline-flex items-center px-2 py-1 text-xs font-medium bg-green-100 text-green-800 rounded">
                Matches found: {matchesFound}
              </span>
            )}
          </div>

          {matches.length > 0 && (
            <div className="space-y-2">
              <h2 className="font-bold text-lg">Matching VCs Found:</h2>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 border">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="px-4 py-2 text-left text-sm font-semibold">VC Firm</th>
                      <th className="px-4 py-2 text-left text-sm font-semibold">Reasons</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {matches.map((m, i) => (
                      <tr key={i}>
                        <td className="px-4 py-2 whitespace-nowrap text-sm">{m.firm}</td>
                        <td className="px-4 py-2 whitespace-normal text-sm">{m.reasons}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {downloadLink && (
            <div className="space-y-2">
              <h2 className="font-bold">Completed!</h2>
              <p>You can now download the full list of matches:</p>
              <a href={downloadLink} download="matches.csv" className="underline text-blue-600">Download CSV</a>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
