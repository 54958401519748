import React from 'react';

/**
 * NavBar
 *
 * Dark and sleek navigation bar with neon accents.
 */
const NavBar = () => {
  return (
    <div className="h-16 bg-gray-900 border-b border-gray-800 flex items-center justify-between px-4">
      <div className="text-xl font-bold text-[#39FF14]">Your SaaS Dashboard</div>
      <div className="flex items-center space-x-4">
        <a href="/auth/logout" className="text-red-400 hover:underline">
          Logout
        </a>
      </div>
    </div>
  );
};

export default NavBar;
