import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadOnboardingState, saveOnboardingState, startOverOnboarding } from './onboardingState';

const Step3Droplet = () => {
  const navigate = useNavigate();
  const initialState = loadOnboardingState();

  const [dropletName, setDropletName] = useState(initialState.dropletName || '');
  const [purchased] = useState(initialState.purchased || false);
  const [dropletCreated, setDropletCreated] = useState(initialState.dropletCreated || false);
  const [creating, setCreating] = useState(false);
  const [result, setResult] = useState(null);

  // Persist changes whenever state updates
  useEffect(() => {
    const updatedState = {
      ...initialState,
      dropletName,
      dropletCreated
    };
    saveOnboardingState(updatedState);
  }, [dropletName, dropletCreated, initialState]);

  async function createDroplet() {
    if (!dropletName || !initialState.domain) {
      alert('Need a domain and droplet name');
      return;
    }
    setCreating(true);
    const res = await fetch('/api/create-droplet', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ droplet_name: dropletName, domain: initialState.domain })
    });
    const data = await res.json();
    setCreating(false);
    setResult(data);
    if (data.status === 'created') {
      setDropletCreated(true);
    }
  }

  function handleNext() {
    const updatedState = { ...initialState, dropletName, dropletCreated, step: 4 };
    saveOnboardingState(updatedState);
    navigate('/onboarding/step4');
  }

  function handlePrev() {
    const updatedState = { ...initialState, step: 2 };
    saveOnboardingState(updatedState);
    navigate('/onboarding/step2');
  }

  function handleStartOver() {
    startOverOnboarding();
    navigate('/onboarding/step1');
  }

  return (
    <div className="max-w-md mx-auto bg-gray-800 p-6 rounded border border-gray-700 shadow space-y-4 relative">
      <div className="absolute top-4 right-4">
        <button
          onClick={handleStartOver}
          className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-500 transition"
        >
          Start Over
        </button>
      </div>
      <h2 className="font-bold text-2xl text-[#39FF14]">Create Your Droplet</h2>
      <p className="text-gray-300">Domain: {initialState.domain}</p>
      <input
        type="text"
        placeholder="Droplet Name"
        className="border border-gray-600 p-2 w-full bg-gray-700 text-white rounded focus:ring-2 focus:ring-[#39FF14]"
        value={dropletName}
        onChange={e => setDropletName(e.target.value)}
      />
      <button
        className="p-2 bg-blue-600 text-white font-bold rounded hover:bg-blue-500 transition"
        onClick={createDroplet}
        disabled={!dropletName || !purchased || creating}
      >
        {creating ? 'Creating...' : 'Create Droplet'}
      </button>

      {result && result.status === 'created' && (
        <div className="mt-4 text-gray-300 space-y-2">
          <p className="text-green-400 font-bold">
            Droplet created: {result.droplet_name}
          </p>
          <p>IP: {result.ip_address}</p>
          <button
            className="mt-2 p-2 bg-[#39FF14] text-gray-900 font-bold rounded hover:opacity-90 transition"
            onClick={handleNext}
          >
            Next
          </button>
        </div>
      )}

      <button
        className="mt-4 p-2 bg-gray-600 text-white rounded hover:bg-gray-500 transition"
        onClick={handlePrev}
      >
        Back
      </button>
    </div>
  );
};

export default Step3Droplet;
