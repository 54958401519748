import React, { useState, useEffect } from 'react';

/**
 * PhoneInput
 *
 * Dark style with neon accents for focus states.
 */
const PhoneInput = ({ phone, setPhone }) => {
  const [countryCode, setCountryCode] = useState('+1');
  const [localNumber, setLocalNumber] = useState('');

  const countryOptions = [
    { code: '+1', label: 'United States (+1)' },
    { code: '+44', label: 'United Kingdom (+44)' },
    { code: '+61', label: 'Australia (+61)' }
  ];

  useEffect(() => {
    const cleanedNumber = localNumber.replace(/[^\d]/g, '');
    const formatted = `${countryCode}.${cleanedNumber}`;
    setPhone(formatted);
  }, [countryCode, localNumber, setPhone]);

  return (
    <div className="mt-2">
      <label className="block mb-1 font-bold text-gray-200">Phone Number</label>
      <div className="flex items-center space-x-2">
        <select
          className="border border-gray-600 p-1 rounded bg-gray-700 text-white focus:ring-2 focus:ring-[#39FF14]"
          value={countryCode}
          onChange={e => setCountryCode(e.target.value)}
        >
          {countryOptions.map(opt => (
            <option key={opt.code} value={opt.code}>
              {opt.label}
            </option>
          ))}
        </select>
        <input
          type="text"
          className="border border-gray-600 p-1 rounded bg-gray-700 text-white focus:ring-2 focus:ring-[#39FF14] flex-1"
          placeholder="Local number"
          value={localNumber}
          onChange={e => setLocalNumber(e.target.value)}
        />
      </div>
      <p className="text-sm text-gray-400 mt-1">Format: {phone}</p>
    </div>
  );
};

export default PhoneInput;
