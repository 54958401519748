import React, { useEffect, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const OnboardingWizard = () => {
  // This component now just acts as a container with a consistent layout
  // The steps are handled as separate pages through <Outlet />.

  const navigate = useNavigate();
  const stateRestored = useRef(false);

  useEffect(() => {
    // If there's no saved state, redirect to step1
    const saved = localStorage.getItem('onboardingState');
    if (!saved) {
      navigate('/onboarding/step1');
    }
    stateRestored.current = true;
  }, [navigate]);

  return (
    <div className="p-4 min-h-screen bg-gray-900 text-white relative">
      <Outlet />
    </div>
  );
};

export default OnboardingWizard;
