import React, { useState, useEffect } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { LiveProvider, LiveEditor, LivePreview, LiveError } from 'react-live';
import ImageUploader from './ImageUploader';

/**
 * CodeManager
 *
 * Modern dark UI with neon green accents. 
 * Allows editing, previewing, and deploying code. Fully responsive layout with a sleek interface.
 */
const CodeManager = () => {
  const [userMessage, setUserMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [files, setFiles] = useState([]);
  const [versions, setVersions] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const [latestCode, setLatestCode] = useState('');
  const [latestExplanation, setLatestExplanation] = useState('');

  const [showNewPageModal, setShowNewPageModal] = useState(false);
  const [newPagePrompt, setNewPagePrompt] = useState('');
  const [latestPageCode, setLatestPageCode] = useState('');
  const [latestPageExplanation, setLatestPageExplanation] = useState('');

  const [previewMode, setPreviewMode] = useState('desktop');
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [visionReply, setVisionReply] = useState('');

  const [chatWidth, setChatWidth] = useState(40);
  const [isDragging, setIsDragging] = useState(false);

  const [schema, setSchema] = useState(null);
  const [contentValues, setContentValues] = useState({});

  useEffect(() => {
    fetchFiles();
    fetchVersions();
  }, []);

  useEffect(() => {
    if (latestCode.trim()) {
      extractSchema();
    } else {
      setSchema(null);
      setContentValues({});
    }
  }, [latestCode]);

  async function fetchFiles() {
    const res = await fetch('/api/list-files');
    const data = await res.json();
    if (data.files) {
      setFiles(data.files);
    }
  }

  async function fetchVersions() {
    const res = await fetch('/api/list-versions');
    const data = await res.json();
    if (data.versions) {
      setVersions(data.versions);
    }
  }

  async function sendMessage() {
    if (!userMessage.trim() && !uploadedImageUrl) return;

    const newUserMessage = { role: 'user', content: userMessage };
    setChatHistory(prev => [...prev, newUserMessage]);

    let endpoint;
    let body;

    if (uploadedImageUrl) {
      endpoint = '/api/chat-with-image';
      body = { prompt: userMessage, imageUrl: uploadedImageUrl };
    } else {
      endpoint = '/generate-code';
      body = { prompt: userMessage };
    }

    try {
      const res = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      });

      const data = await res.json();

      if (data.error) {
        const errorMsg = {
          role: 'assistant',
          content: { explanation: data.error }
        };
        setChatHistory(prev => [...prev, errorMsg]);
        setLatestCode('');
        setLatestExplanation(data.error);
      } else {
        const explanation = data.explanation || 'No explanation provided.';
        const code = data.code || '';

        const assistantMsg = {
          role: 'assistant',
          content: { explanation }
        };
        setChatHistory(prev => [...prev, assistantMsg]);

        setLatestExplanation(explanation);
        setLatestCode(code);
      }
    } catch (err) {
      console.error('Error sending message:', err);
      const errorAssistantMessage = {
        role: 'assistant',
        content: { explanation: 'An error occurred while communicating with the server.' }
      };
      setChatHistory(prev => [...prev, errorAssistantMessage]);
      setLatestCode('');
      setLatestExplanation('An error occurred.');
    } finally {
      setUserMessage('');
      setUploadedImageUrl('');
      setVisionReply('');
    }
  }

  async function createNewPage() {
    if (!newPagePrompt.trim()) return;
    const res = await fetch('/generate-code', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ prompt: newPagePrompt })
    });

    let data;
    try {
      data = await res.json();
    } catch (err) {
      alert('Error receiving JSON from model.');
      return;
    }

    if (!data.code) {
      alert('No code received. Please try again.');
      return;
    }

    setLatestPageExplanation(data.explanation || '');
    setLatestPageCode(data.code || '');
    setShowNewPageModal(false);
    setNewPagePrompt('');

    const assistantMessage = {
      role: 'assistant',
      content: { explanation: data.explanation || '' }
    };
    setChatHistory(prev => [...prev, { role: 'user', content: newPagePrompt }, assistantMessage]);
  }

  async function applyChanges() {
    if (!latestCode) {
      alert('No code available to apply.');
      return;
    }
    const res = await fetch('/api/deploy', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ code: latestCode, type: 'frontend' })
    });
    const data = await res.json();
    if (data.status === 'deployed') {
      alert('Changes applied successfully!');
      fetchFiles();
      fetchVersions();
      setPreviewUrl('https://the-user-domain.com');
    } else {
      alert(`Failed to apply changes: ${data.error || 'Unknown error'}`);
    }
  }

  async function deployNewPage() {
    if (!latestPageCode) {
      alert('No page code available to deploy.');
      return;
    }
    const res = await fetch('/api/deploy', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ code: latestPageCode, type: 'frontend' })
    });
    const data = await res.json();
    if (data.status === 'deployed') {
      alert('New page deployed successfully!');
      fetchFiles();
      fetchVersions();
      setPreviewUrl('https://the-user-domain.com');
    } else {
      alert(`Failed to deploy page: ${data.error || 'Unknown error'}`);
    }
  }

  async function revertVersion(versionId) {
    const res = await fetch('/api/revert-version', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ versionId })
    });
    const data = await res.json();
    if (data.status === 'reverted') {
      alert('Reverted to the selected version!');
      fetchFiles();
      fetchVersions();
    } else {
      alert(`Failed to revert: ${data.error || 'Unknown error'}`);
    }
  }

  async function loadFileContent(fileName) {
    const res = await fetch('/api/get-file', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ fileName })
    });
    const data = await res.json();
    if (data.content) {
      const assistantMessage = {
        role: 'assistant',
        content: { explanation: `Loaded existing file: ${fileName}` }
      };
      setChatHistory(prev => [...prev, assistantMessage]);
      setLatestExplanation(`Editing file: ${fileName}`);
      setLatestCode(data.content);
      setSelectedFile(fileName);
    } else {
      alert('Failed to load file content.');
    }
  }

  async function extractSchema() {
    if (!latestCode.trim()) return;
    const res = await fetch('/api/extract-schema', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ code: latestCode })
    });
    const data = await res.json();
    if (data.schema) {
      setSchema(data.schema);
      const initialValues = buildInitialValuesFromSchema(data.schema);
      setContentValues(initialValues);
    } else {
      setSchema(null);
      setContentValues({});
    }
  }

  function buildInitialValuesFromSchema(schemaObj) {
    const values = {};
    if (schemaObj && schemaObj.properties) {
      for (const key in schemaObj.properties) {
        const prop = schemaObj.properties[key];
        values[key] = prop.default || '';
      }
    }
    return values;
  }

  function updateContentValue(fieldKey, value) {
    setContentValues(prev => ({
      ...prev,
      [fieldKey]: value
    }));
  }

  async function updateCodeFromSchema() {
    if (!latestCode || !schema) {
      alert('No code or schema available to update.');
      return;
    }
    const res = await fetch('/api/update-code-from-schema', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ originalCode: latestCode, updatedFields: contentValues })
    });

    const data = await res.json();
    if (data.error) {
      alert('Error updating code: ' + data.error);
    } else {
      setLatestExplanation(data.explanation || 'Updated code from schema.');
      setLatestCode(data.code || '');
      const assistantMessage = {
        role: 'assistant',
        content: { explanation: data.explanation || '' }
      };
      setChatHistory(prev => [...prev, assistantMessage]);
    }
  }

  function renderFormFields(schemaObj) {
    if (!schemaObj || !schemaObj.properties) return null;
    return Object.keys(schemaObj.properties).map(key => {
      const field = schemaObj.properties[key];
      return (
        <div className="mb-3" key={key}>
          <label className="block text-sm font-bold mb-1 text-gray-200">
            {field.title || key}
          </label>
          <input
            className="border border-gray-600 bg-gray-800 p-1 w-full rounded text-white focus:ring-2 focus:ring-[#39FF14] focus:outline-none"
            value={contentValues[key] || ''}
            onChange={e => updateContentValue(key, e.target.value)}
          />
        </div>
      );
    });
  }

  const previewWidths = {
    mobile: '375px',
    tablet: '768px',
    desktop: '1024px'
  };

  useEffect(() => {
    const handleMouseMove = e => {
      if (!isDragging) return;
      const container = document.getElementById('code-manager-container');
      if (!container) return;
      const containerWidth = container.offsetWidth;
      const newChatWidth = (e.clientX / containerWidth) * 100;
      if (newChatWidth > 20 && newChatWidth < 80) {
        setChatWidth(newChatWidth);
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  const previewCode = latestCode;

  return (
    <div className="h-screen flex flex-col bg-gray-900 text-white" id="code-manager-container">
      {showNewPageModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
          <div className="bg-gray-800 p-4 rounded shadow max-w-md w-full border border-gray-700">
            <h2 className="text-xl font-bold mb-2 text-white">Create a New Page</h2>
            <p className="text-gray-300 mb-2">Describe what this page should look like:</p>
            <textarea
              className="border border-gray-600 w-full p-2 mb-4 bg-gray-700 text-white rounded focus:ring-2 focus:ring-[#39FF14]"
              rows={4}
              value={newPagePrompt}
              onChange={e => setNewPagePrompt(e.target.value)}
            />
            <div className="flex justify-end space-x-2">
              <button
                className="p-2 bg-gray-600 text-white rounded hover:bg-gray-500 transition"
                onClick={() => {
                  setShowNewPageModal(false);
                  setNewPagePrompt('');
                }}
              >
                Cancel
              </button>
              <button
                className="p-2 bg-[#39FF14] text-gray-900 font-bold rounded hover:opacity-90 transition"
                onClick={createNewPage}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="flex-1 flex overflow-hidden">
        {/* Chat Panel */}
        <div className="flex-shrink-0 border-r border-gray-800" style={{ width: `${chatWidth}%` }}>
          <div className="flex flex-col h-full">
            <div className="flex-1 overflow-auto p-4 space-y-4">
              {chatHistory.length === 0 && (
                <p className="text-gray-400">Start by typing a prompt or uploading an image.</p>
              )}
              {chatHistory.map((msg, idx) => (
                <div key={idx} className={`max-w-lg ${msg.role === 'user' ? 'ml-auto' : ''}`}>
                  <div
                    className={
                      msg.role === 'user'
                        ? 'bg-[#39FF14] text-black p-2 rounded-lg mb-4'
                        : 'bg-gray-800 p-2 rounded-lg border border-gray-700 mb-4 text-gray-200'
                    }
                  >
                    <strong>{msg.role === 'user' ? 'You' : 'AI'}:</strong>
                    {msg.content.explanation && (
                      <p className="whitespace-pre-wrap mt-1">{msg.content.explanation}</p>
                    )}
                    {!msg.content.explanation && msg.content && (
                      <p className="whitespace-pre-wrap mt-1">{msg.content}</p>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div className="border-t border-gray-800 p-2 flex items-center space-x-2 bg-gray-800">
              {uploadedImageUrl && (
                <div className="relative">
                  <img
                    src={uploadedImageUrl}
                    alt="Uploaded"
                    className="w-16 h-16 object-cover rounded"
                  />
                  <button
                    className="absolute top-0 right-0 bg-red-600 text-white text-xs rounded-full px-1"
                    onClick={() => setUploadedImageUrl('')}
                  >
                    x
                  </button>
                </div>
              )}
              {!uploadedImageUrl && <ImageUploader onImageUploaded={url => setUploadedImageUrl(url)} />}
              <input
                className="border border-gray-700 bg-gray-700 p-2 flex-1 rounded text-white focus:ring-2 focus:ring-[#39FF14] focus:outline-none"
                type="text"
                placeholder="Type a message..."
                value={userMessage}
                onChange={e => setUserMessage(e.target.value)}
                onKeyDown={e => {
                  if (e.key === 'Enter') sendMessage();
                }}
              />
              <button
                className="p-2 bg-[#39FF14] text-gray-900 font-bold rounded hover:opacity-90 transition"
                onClick={sendMessage}
              >
                Send
              </button>
            </div>
          </div>
        </div>

        {/* Divider */}
        <div
          onMouseDown={() => setIsDragging(true)}
          className="w-1 bg-gray-700 cursor-col-resize"
        ></div>

        {/* Code Panel */}
        <div className="flex-1 flex flex-col overflow-auto p-4 space-y-6">
          <div className="space-y-4">
            <h3 className="font-bold text-2xl text-[#39FF14]">Dynamic CMS Fields</h3>
            {schema ? (
              <div>
                <p className="text-gray-300 mb-3">
                  Adjust fields below, then "Update Code from Fields" to modify code dynamically.
                </p>
                {renderFormFields(schema)}
                <button
                  className="p-2 bg-[#39FF14] text-gray-900 font-bold rounded hover:opacity-90 transition"
                  onClick={updateCodeFromSchema}
                >
                  Update Code from Fields
                </button>
              </div>
            ) : (
              <p className="text-gray-500">No editable fields detected yet. Generate code first.</p>
            )}
          </div>

          <div className="space-y-4">
            <h3 className="font-bold text-2xl text-[#39FF14]">Latest Generated Code</h3>
            <p className="text-gray-300 text-sm mb-2">
              Apply changes or deploy a new page from the code below.
            </p>

            {latestCode ? (
              <>
                <div>
                  <strong className="block mb-2 text-gray-200">Proposed Code:</strong>
                  <div className="bg-gray-800 text-white text-sm rounded p-2 border border-gray-700 overflow-auto">
                    <SyntaxHighlighter language="jsx" style={vscDarkPlus}>
                      {latestCode}
                    </SyntaxHighlighter>
                  </div>
                </div>

                <div className="flex space-x-4 flex-wrap">
                  <div>
                    <h3 className="font-bold text-lg mb-2 text-gray-200">Apply Changes</h3>
                    <button
                      className="p-2 bg-[#39FF14] text-gray-900 font-bold rounded hover:opacity-90 transition"
                      onClick={applyChanges}
                    >
                      Apply Latest Code
                    </button>
                  </div>

                  <div>
                    <h3 className="font-bold text-lg mb-2 text-gray-200">Deploy New Page</h3>
                    <button
                      className="p-2 bg-[#39FF14] text-gray-900 font-bold rounded hover:opacity-90 transition"
                      onClick={deployNewPage}
                      disabled={!latestPageCode}
                    >
                      Deploy Page
                    </button>
                  </div>
                </div>

                <h3 className="font-bold text-2xl text-[#39FF14] mt-4">Live Preview</h3>
                <p className="text-gray-300 text-sm mb-2">
                  Preview your code below. Switch between device sizes for responsive testing.
                </p>

                <div className="flex space-x-2 mb-2">
                  {['mobile', 'tablet', 'desktop'].map(mode => (
                    <button
                      key={mode}
                      className={`p-2 rounded font-bold ${
                        previewMode === mode
                          ? 'bg-[#39FF14] text-gray-900'
                          : 'bg-gray-700 text-gray-200 hover:bg-gray-600'
                      } transition`}
                      onClick={() => setPreviewMode(mode)}
                    >
                      {mode.charAt(0).toUpperCase() + mode.slice(1)}
                    </button>
                  ))}
                </div>

                <button
                  className="p-2 bg-[#39FF14] text-gray-900 font-bold rounded hover:opacity-90 transition"
                  onClick={() => setIsFullScreen(true)}
                >
                  Full Screen Preview
                </button>

                {isFullScreen && (
                  <div className="fixed inset-0 bg-black bg-opacity-75 flex flex-col items-center justify-center z-50 p-4">
                    <div className="bg-gray-800 rounded overflow-auto relative w-full h-full max-w-5xl max-h-full border border-gray-700">
                      <div className="p-2 border-b border-gray-700 flex justify-between items-center">
                        <span className="font-bold text-gray-200">Full Screen Preview</span>
                        <button
                          className="p-2 bg-red-600 text-white rounded hover:bg-red-500 transition"
                          onClick={() => setIsFullScreen(false)}
                        >
                          Close
                        </button>
                      </div>
                      <div className="p-4 overflow-auto" style={{ height: 'calc(100% - 3rem)' }}>
                        <div
                          style={{
                            width: '100%',
                            border: '1px solid #555',
                            padding: '10px',
                            margin: 'auto',
                            backgroundColor: '#1a1a1a'
                          }}
                        >
                          <LiveProvider code={previewCode} noInline={true}>
                            <LivePreview className="mb-2" />
                            <LiveError className="text-red-400 text-sm" />
                          </LiveProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <LiveProvider code={previewCode} noInline={true}>
                  <div className="border border-gray-700 p-2 bg-gray-800 mt-4 rounded space-y-4">
                    <h4 className="font-bold mb-2 text-gray-200">
                      Preview ({previewMode.charAt(0).toUpperCase() + previewMode.slice(1)})
                    </h4>
                    <div
                      className="mx-auto border border-gray-600 p-2 bg-black"
                      style={{ width: previewWidths[previewMode] }}
                    >
                      <LivePreview className="mb-2 text-white" />
                      <LiveError className="text-red-400 text-sm" />
                    </div>

                    <h4 className="font-bold mb-2 mt-4 text-gray-200">Code Editor</h4>
                    <LiveEditor
                      className="border border-gray-700 p-2 bg-gray-900 text-sm h-64 overflow-auto rounded"
                      style={{ fontFamily: 'monospace', color: '#39FF14' }}
                    />
                  </div>
                </LiveProvider>
              </>
            ) : (
              <p className="text-gray-500">No code yet. Interact with the chat to generate code.</p>
            )}

            {previewUrl && (
              <div className="mt-4">
                <h4 className="font-bold mb-1 text-gray-200">View Your Site</h4>
                <a
                  href={previewUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#39FF14] underline hover:opacity-90 transition"
                >
                  {previewUrl}
                </a>
              </div>
            )}
          </div>

          <div className="flex space-x-4">
            <div className="flex-1 bg-gray-800 border border-gray-700 rounded p-4 h-48 overflow-auto">
              <h3 className="font-bold mb-2 text-gray-200">Your Files</h3>
              {files.length === 0 && <p className="text-gray-500 text-sm">No files yet.</p>}
              <ul className="space-y-1">
                {files.map(f => (
                  <li key={f}>
                    <button
                      onClick={() => loadFileContent(f)}
                      className={`block w-full text-left px-2 py-1 rounded ${
                        selectedFile === f
                          ? 'bg-[#39FF14] text-gray-900'
                          : 'text-gray-200 hover:bg-gray-700'
                      } transition`}
                    >
                      {f}
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            <div className="w-1/2 bg-gray-800 border border-gray-700 rounded p-4 h-48 overflow-auto">
              <h3 className="font-bold mb-2 text-gray-200">Version History</h3>
              {versions.length === 0 && <p className="text-gray-500 text-sm">No versions yet.</p>}
              <ul className="space-y-1">
                {versions.map(v => (
                  <li key={v.id} className="flex justify-between items-center text-gray-200">
                    <span className="text-sm">{v.label}</span>
                    <button
                      onClick={() => revertVersion(v.id)}
                      className="text-[#39FF14] hover:opacity-80 text-sm"
                    >
                      Revert
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodeManager;
