import React from 'react';

const Privacy = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-white p-4">
      <div className="max-w-3xl mx-auto bg-gray-800 p-6 rounded border border-gray-700 shadow space-y-4">
        <h1 className="text-2xl font-bold text-[#39FF14]">Privacy Policy</h1>
        <p className="text-gray-300">
          We value your privacy and are committed to protecting your personal information.
        </p>

        <h2 className="text-xl font-bold text-gray-200 mt-4">1. Information We Collect</h2>
        <p className="text-gray-300">
          We may collect personal information such as your name, email address, and other details you choose to provide.
        </p>

        <h2 className="text-xl font-bold text-gray-200 mt-4">2. Use of Information</h2>
        <p className="text-gray-300">
          We use the information collected to improve our website, respond to inquiries, and enhance user experience.
        </p>

        <h2 className="text-xl font-bold text-gray-200 mt-4">3. Sharing of Information</h2>
        <p className="text-gray-300">
          We do not share your personal information with third parties unless required by law.
        </p>

        <h2 className="text-xl font-bold text-gray-200 mt-4">4. Cookies</h2>
        <p className="text-gray-300">
          We use cookies to enhance your browsing experience. You can disable cookies in your browser settings.
        </p>

        <h2 className="text-xl font-bold text-gray-200 mt-4">5. Security</h2>
        <p className="text-gray-300">
          We implement security measures to protect your information. However, no method is 100% secure.
        </p>

        <h2 className="text-xl font-bold text-gray-200 mt-4">6. Changes to This Policy</h2>
        <p className="text-gray-300">
          We may update this Policy. Continued use after changes are posted constitutes acceptance.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
