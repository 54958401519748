import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadOnboardingState, saveOnboardingState, startOverOnboarding } from './onboardingState';

const Step1DomainInput = () => {
  const navigate = useNavigate();
  const initialState = loadOnboardingState();
  
  const [domain, setDomain] = useState(initialState.domain || '');

  useEffect(() => {
    const updatedState = { ...initialState, domain };
    saveOnboardingState(updatedState);
  }, [domain, initialState]);

  function handleNext() {
    const updatedState = { ...initialState, domain: domain.trim(), step: 2 };
    saveOnboardingState(updatedState);
    navigate('/onboarding/step2');
  }

  function handleStartOver() {
    startOverOnboarding();
    navigate('/onboarding/step1');
  }

  return (
    <div className="max-w-md mx-auto bg-gray-800 p-6 rounded border border-gray-700 shadow space-y-4 relative">
      <div className="absolute top-4 right-4">
        <button
          onClick={handleStartOver}
          className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-500 transition"
        >
          Start Over
        </button>
      </div>
      <h2 className="font-bold text-2xl text-[#39FF14]">Welcome to Your SaaS Setup</h2>
      <p className="text-gray-300">Let's start by choosing a domain name for your SaaS.</p>
      <form onSubmit={(e) => {e.preventDefault(); handleNext();}} className="space-y-4">
        <input
          type="text"
          className="border border-gray-600 p-2 w-full bg-gray-700 text-white rounded focus:ring-2 focus:ring-[#39FF14]"
          placeholder="e.g. myawesomeservice.com"
          value={domain}
          onChange={e => setDomain(e.target.value)}
        />
        <button
          type="submit"
          className="bg-[#39FF14] text-gray-900 font-bold p-2 rounded hover:opacity-90 transition"
          disabled={!domain.trim()}
        >
          Next
        </button>
      </form>
    </div>
  );
};

export default Step1DomainInput;
