import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadOnboardingState, saveOnboardingState, startOverOnboarding } from './onboardingState';

const Step4CodeGeneration = () => {
  const navigate = useNavigate();
  const initialState = loadOnboardingState();

  const [generatedCode, setGeneratedCode] = useState(initialState.generatedCode || '');
  const [testOutput, setTestOutput] = useState(initialState.testOutput || '');
  const [prompt, setPrompt] = useState('');

  // Persist changes whenever fields update
  useEffect(() => {
    const updatedState = { ...initialState, generatedCode, testOutput };
    saveOnboardingState(updatedState);
  }, [generatedCode, testOutput, initialState]);

  async function handleGenerateCode() {
    if (!prompt.trim()) return;
    const res = await fetch('/generate-code', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ prompt })
    });
    const data = await res.json();
    if (data.code) {
      setGeneratedCode(data.code);
    } else {
      alert('No code returned. Try a different prompt.');
    }
  }

  async function testCode() {
    if (!generatedCode) return;
    const res = await fetch('/api/test-code', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ code: generatedCode })
    });
    const data = await res.json();
    setTestOutput(data.output || data.errors || '');
  }

  async function deployCode() {
    if (!generatedCode) return;
    const res = await fetch('/api/deploy', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ code: generatedCode })
    });
    const data = await res.json();
    if (data.status === 'deployed') {
      const updatedState = { ...initialState, generatedCode, testOutput, step: 5 };
      saveOnboardingState(updatedState);
      navigate('/onboarding/step5');
    } else {
      alert(`Failed to deploy code: ${data.error || 'Unknown error'}`);
    }
  }

  function handlePrev() {
    const updatedState = { ...initialState, generatedCode, testOutput, step: 3 };
    saveOnboardingState(updatedState);
    navigate('/onboarding/step3');
  }

  function handleStartOver() {
    startOverOnboarding();
    navigate('/onboarding/step1');
  }

  return (
    <div className="max-w-md mx-auto bg-gray-800 p-6 rounded border border-gray-700 shadow space-y-4 relative">
      <div className="absolute top-4 right-4">
        <button
          onClick={handleStartOver}
          className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-500 transition"
        >
          Start Over
        </button>
      </div>
      <h2 className="font-bold text-2xl text-[#39FF14]">Generate & Deploy Your Code</h2>
      <textarea
        className="border border-gray-600 p-2 w-full mt-2 bg-gray-700 text-white rounded focus:ring-2 focus:ring-[#39FF14]"
        value={prompt}
        onChange={e => setPrompt(e.target.value)}
        placeholder="Enter a prompt for code generation..."
      />
      <button
        className="mt-2 p-2 bg-[#39FF14] text-gray-900 font-bold rounded hover:opacity-90 transition"
        onClick={handleGenerateCode}
      >
        Generate Code
      </button>

      {generatedCode && (
        <pre className="mt-4 p-2 border border-gray-600 bg-gray-800 whitespace-pre-wrap text-gray-200 rounded">
          {generatedCode}
        </pre>
      )}

      {generatedCode && (
        <>
          <button
            className="mt-2 p-2 bg-green-600 text-white font-bold rounded hover:bg-green-500 transition"
            onClick={testCode}
          >
            Test Code
          </button>
          {testOutput && (
            <pre className="mt-4 border border-gray-600 p-2 bg-gray-800 text-gray-200 rounded">
              {testOutput}
            </pre>
          )}

          <button
            className="mt-2 p-2 bg-purple-600 text-white font-bold rounded hover:bg-purple-500 transition"
            onClick={deployCode}
          >
            Deploy Code
          </button>
        </>
      )}

      <button
        className="mt-4 p-2 bg-gray-600 text-white rounded hover:bg-gray-500 transition"
        onClick={handlePrev}
      >
        Back
      </button>
    </div>
  );
};

export default Step4CodeGeneration;
