import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

/**
 * ImageUploader
 *
 * Styled for a sleek dark interface. Neon green highlights on hover.
 */
const ImageUploader = ({ onImageUploaded }) => {
  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        const formData = new FormData();
        formData.append('image', file);

        try {
          const res = await fetch('/api/upload-image', {
            method: 'POST',
            body: formData
          });

          const data = await res.json();
          if (data.imageUrl) {
            onImageUploaded(data.imageUrl);
          } else {
            alert('Failed to upload image.');
          }
        } catch (err) {
          console.error('Image upload error:', err);
          alert('Error uploading image.');
        }
      }
    },
    [onImageUploaded]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'image/png': [],
      'image/jpeg': [],
      'image/gif': [],
      'image/webp': []
    }
  });

  return (
    <div
      {...getRootProps()}
      className="border border-dashed border-gray-600 p-4 text-center rounded cursor-pointer bg-gray-700 hover:border-[#39FF14] hover:text-[#39FF14] transition text-gray-300"
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the image here ...</p>
      ) : (
        <p>Drag &amp; drop an image, or click to select a file</p>
      )}
    </div>
  );
};

export default ImageUploader;
