import React from 'react';

const Terms = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-white p-4">
      <div className="max-w-3xl mx-auto bg-gray-800 p-6 rounded border border-gray-700 shadow space-y-4">
        <h1 className="text-2xl font-bold text-[#39FF14]">Terms and Conditions</h1>
        <p className="text-gray-300">
          By accessing or using this site, you agree to these Terms and Conditions.
        </p>

        <h2 className="text-xl font-bold text-gray-200 mt-4">1. Use of the Website</h2>
        <p className="text-gray-300">
          You agree to use the website in compliance with all applicable laws and not for unlawful purposes.
        </p>

        <h2 className="text-xl font-bold text-gray-200 mt-4">2. Intellectual Property</h2>
        <p className="text-gray-300">
          All content is owned or licensed by us. You may not reproduce or distribute it without permission.
        </p>

        <h2 className="text-xl font-bold text-gray-200 mt-4">3. Modifications</h2>
        <p className="text-gray-300">
          We may modify or discontinue the site or these terms at any time without notice.
        </p>

        <h2 className="text-xl font-bold text-gray-200 mt-4">4. Limitation of Liability</h2>
        <p className="text-gray-300">
          We are not liable for damages arising from your use of or inability to use the website.
        </p>

        <h2 className="text-xl font-bold text-gray-200 mt-4">5. Governing Law</h2>
        <p className="text-gray-300">
          These Terms are governed by the laws of your jurisdiction. Disputes will be resolved in those courts.
        </p>
      </div>
    </div>
  );
};

export default Terms;
