import React, { useState, useEffect } from 'react';

/**
 * PageManager
 *
 * Dark interface, neon accents. Manages pages with a clean list and form layout.
 */
const PageManager = () => {
  const [pages, setPages] = useState([]);
  const [newPagePrompt, setNewPagePrompt] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchPages();
  }, []);

  async function fetchPages() {
    const res = await fetch('/api/list-pages');
    const data = await res.json();
    if (data.pages) {
      setPages(data.pages);
    }
  }

  async function addNewPage() {
    if (!newPagePrompt.trim()) return;
    setLoading(true);
    const res = await fetch('/api/generate-page', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ prompt: newPagePrompt })
    });
    const data = await res.json();
    setLoading(false);
    if (data.status === 'page_created') {
      alert('New page created!');
      setNewPagePrompt('');
      fetchPages();
    } else {
      alert(`Failed to create page: ${data.error || 'Unknown error'}`);
    }
  }

  return (
    <div className="min-h-screen p-6 bg-gray-900 text-white">
      <h2 className="text-3xl font-bold mb-4 text-[#39FF14]">Page Manager</h2>
      <p className="text-gray-300 mb-8">
        Create and manage pages. Add a new page by describing it below.
      </p>

      <div className="space-y-8 max-w-2xl">
        <div className="bg-gray-800 border border-gray-700 rounded p-4">
          <h3 className="font-bold text-lg text-gray-200 mb-2">Existing Pages:</h3>
          {pages.length === 0 ? (
            <p className="text-gray-400">No pages yet. Add one below.</p>
          ) : (
            <ul className="list-disc list-inside text-gray-200">
              {pages.map((page, idx) => (
                <li key={idx}>{page}</li>
              ))}
            </ul>
          )}
        </div>

        <div className="bg-gray-800 border border-gray-700 rounded p-4 space-y-4">
          <h3 className="font-bold text-lg text-gray-200">Add a New Page</h3>
          <textarea
            className="border border-gray-600 p-2 w-full h-24 bg-gray-700 text-white rounded focus:ring-2 focus:ring-[#39FF14]"
            placeholder="Describe the new page..."
            value={newPagePrompt}
            onChange={e => setNewPagePrompt(e.target.value)}
          />
          <button
            className="p-2 bg-[#39FF14] text-gray-900 font-bold rounded hover:opacity-90 transition"
            onClick={addNewPage}
            disabled={loading}
          >
            {loading ? 'Creating...' : 'Generate New Page'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PageManager;
