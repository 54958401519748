// onboardingState.js
export function loadOnboardingState() {
  const saved = localStorage.getItem('onboardingState');
  if (saved) {
    return JSON.parse(saved);
  }
  return {
    step: 1,
    domain: '',
    domainAvailable: false,
    contactInfo: {
      firstname: '',
      lastname: '',
      address1: '',
      city: '',
      stateprovince: '',
      postalcode: '',
      country: 'US',
      phone: '',
      emailaddress: ''
    },
    purchased: false,
    dropletName: '',
    dropletCreated: false,
    generatedCode: '',
    testOutput: ''
  };
}

export function saveOnboardingState(state) {
  localStorage.setItem('onboardingState', JSON.stringify(state));
}

export function startOverOnboarding() {
  localStorage.removeItem('onboardingState');
}
