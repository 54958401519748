import React, { useState } from 'react';

/**
 * PaymentForm
 *
 * Clean dark theme with neon accent on the Pay Now button.
 */
const PaymentForm = ({ domain, price }) => {
  const [loading, setLoading] = useState(false);

  async function handlePay() {
    setLoading(true);
    try {
      const res = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ domain, price })
      });
      const data = await res.json();
      setLoading(false);
      if (data.url) {
        window.location.href = data.url;
      } else {
        alert('Error creating checkout session: ' + data.error);
      }
    } catch (err) {
      setLoading(false);
      alert(`Error during payment: ${err.message}`);
    }
  }

  return (
    <div className="mt-4">
      <h3 className="font-bold text-gray-200">Price: ${price}</h3>
      <button
        className="mt-2 p-2 bg-[#39FF14] text-gray-900 font-bold rounded hover:opacity-90 transition"
        onClick={handlePay}
        disabled={loading}
      >
        {loading ? 'Redirecting...' : 'Pay Now'}
      </button>
    </div>
  );
};

export default PaymentForm;
